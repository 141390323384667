var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content flex w-full h-full"
  }, [!_vm.isMobile ? _c('div', {
    staticClass: "navbar",
    class: {
      'shrink-menu': !_vm.expandedMenu
    }
  }, [_c('TheSidebar', {
    attrs: {
      "sidebarStatus": _vm.expandedMenu
    }
  })], 1) : _c('div', {
    staticClass: "mobile-sidebar",
    class: {
      'expand-menu': !_vm.expandedMenu
    }
  }, [_c('TheMobileSidebar', {
    attrs: {
      "sidebarStatus": _vm.expandedMenu
    }
  })], 1), _vm.isMobile && !_vm.expandedMenu ? _c('div', {
    staticClass: "overlay",
    on: {
      "click": function ($event) {
        return _vm.toggleMenu();
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "content_wrapper w-full"
  }, [_c('div', {
    staticClass: "header",
    class: {
      'shrink-header': !_vm.expandedMenu && !this.isMobile
    }
  }, [_c('TheHeader'), _c('div', {
    staticClass: "text-left flex px-3 py-3 bg-white items-center"
  }, [_c('div', {
    staticClass: "texl-lg font-bold py-1 border-r-2 border-neutral-700 leading-none pr-2"
  }, [_vm._v(_vm._s(_vm.$route.name))]), _c('router-link', {
    staticClass: "pl-2 text-xs text-neutral-500 pr-2",
    attrs: {
      "to": "/dashboard"
    }
  }, [_vm._v("Beranda")]), _vm.$route.name !== 'Dashboard' ? _c('span', {
    staticClass: "inline-block text-xs text-neutral-700"
  }, [_vm._v("/ " + _vm._s(_vm.$route.name))]) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "panel h-full flex-col"
  }, [_c('div', {
    staticClass: "w-full mx-auto py-6 md:w-3/4 mdmax:mx-0 px-4 mdmax:py-4"
  }, [_c('router-view')], 1)]), _c('Toast')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }